// src\store\profile\dialogs.js

import dialogsApi from '@/requests/dialogs';
 
export default {
  namespaced: true,
  state: {
    dialogs: [],
    unreadedMessages: 0, // total unreaded
    lastMessages: {
      messages: [],
      totalPages: null,
    },
    nextMessages: [],
    newMessage: {},
    mySubmitMessage: {},
    allMessages: [],
    totalNextMessages: null,
    totalLastMessages: null,
    dialogsLoaded: false,
    activeDialogId: null,
    oldLastKnownMessageId: null,
    isHistoryEndReached: false,
  },
  getters: {
    getDialogs: (state) => state.dialogs,
    getNewMessage: (state) => state.newMessage,
    getSubmitMessage: (state) => state.mySubmitMessage,
    getNextMessages: (state) => state.nextMessages,
    getLastMessages: (state) => state.lastMessages,
    oldestKnownMessageId: (state) => (state.messages),

    activeDialog: (state) => {
      if (!state.dialogs || !Array.isArray(state.dialogs)) {
        console.warn("activeDialog: Dialogs не загружены или это не массив");
        return null;
      }
      const dialog = state.dialogs.find((el) => el.id == state.activeDialogId);
      console.log("activeDialog getter: Найденный диалог:", dialog);
      return dialog ? { ...dialog } : null;
    },
    
    getActiveDialogId: (state) => state.activeDialogId,
    dialogsLoaded: (state) => state.dialogsLoaded,
    unreadedMessages: (state) => state.unreadedMessages,
    hasOpenedDialog: (state) => (dialogId) => !!state.dialogs.find((el) => el.id == dialogId),
    isHistoryEndReached: (state) => state.isHistoryEndReached,
  },
  mutations: {
    clearLastMessages(state) {
      state.lastMessages.messages = [];
      state.lastMessages.totalPages = null;
    },

    setUnreadedMessages: (state, unread) => (state.unreadedMessages = unread),
    setDialogs: (state, dialogs) => {
      state.dialogs = dialogs.map(dialog => ({
        ...dialog,
        lastMessage: dialog.lastMessage || { messageText: "Диалог начат, сообщений нет", time: null }
      }));
    },
    updateLastMessage: (state, { dialogId, message }) => {
      const dialog = state.dialogs.find(d => d.id === dialogId);
      if (dialog) {
        console.log(`Обновление lastMessage для диалога ${dialogId}:`, message);
        dialog.lastMessage = message;
      } else {
        console.warn(`Диалог с id ${dialogId} не найден при обновлении lastMessage`);
      }
    },    
    setNewMessage: (state, newMessage) => {
      console.log("[Vuex] Добавлено новое сообщение:", newMessage);
      console.log("[Vuex] Все сообщения:", state.allMessages);

      state.newMessage = newMessage;
      state.allMessages = [...state.allMessages, newMessage];
    },
    setSubmitMessage: (state, submitMessage) => {
      state.mySubmitMessage = submitMessage;
      state.allMessages = [...state.allMessages, submitMessage];
    },
    dialogsLoaded: (state) => (state.dialogsLoaded = true),

    setActiveDialogId: (state, value) => {
      console.log("[Vuex] Изменение activeDialogId:", value);
      state.activeDialogId = value;
    },
 
    setActiveDialog: (state, dialog) => {
      console.log("Установка activeDialog в Vuex (mutations):", dialog);
      state.activeDialog = JSON.parse(JSON.stringify(dialog));
    },

    addNextMessages: (state, {
      nextMessages,
      total
    }) => {
      state.nextMessages = nextMessages;
      state.nextMessages.sort((a, b) => a.time - b.time);
      state.allMessages = [...state.nextMessages, ...state.allMessages];
      state.totalNextMessages = total;
    },
    addLastMessages: (state, {
      lastMessages,
      total
    }) => {
      state.lastMessages.totalPages = lastMessages.totalPages;
      state.lastMessages.messages = lastMessages.content;
      state.lastMessages.messages.sort((a, b) => b.time - a.time);
      state.allMessages = state.lastMessages.messages;
      state.totalLastMessages = total;
    },

    selectDialog: (state, dialogId) => {
      state.activeId = dialogId;
      state.messages = [];
      state.isHistoryEndReached = false;
    },
    markEndOfHistory: (state) => (state.isHistoryEndReached = true),

    // Мутация. Увеличивает счётчик непрочитанных сообщений для неактивного диалога.
    increaseUnreadCount(state, recipientId) {
      const dialog = state.dialogs.find(d =>
        d.conversationPartner1 === recipientId || d.conversationPartner2 === recipientId
      );
      if (dialog) {
        dialog.unreadCount += 1;
      }
    },
    
    // Мутация. Сбрасывает счётчик непрочитанных сообщений, если сообщение относится к активному диалогу.
    resetUnreadCount(state, dialogId) {
      const dialog = state.dialogs.find(d => d.id === dialogId);
      if (dialog) {
        dialog.unreadCount = 0;
      }
    },
  },

  actions: {

    setActiveDialogId({ commit }, dialogId) {
      console.log("setActiveDialogId. Установка activeDialogId во Vuex (actions):", dialogId);
      commit('setActiveDialogId', dialogId);
      console.log('setActiveDialogId. Текущий activeDialogId обновлен:', dialogId);
    },

    async newDialogs({ commit, state }, recipientId) {
      try {
        console.log("Попытка получить или создать новый диалог с:", recipientId);
    
        const response = await dialogsApi.newDialogs(recipientId);
        console.log("Ответ от сервера для newDialogs:", response);
    
        if (!response.data) return;
    
        const dialogData = {
          ...response.data,
          lastMessage: response.data.lastMessage || [], // Обеспечиваем пустое сообщение
        };

        // Проверка на существование диалога
        if (!state.dialogs.some(d => d.id === dialogData.id)) {
          commit('setDialogs', [...state.dialogs, dialogData]); // Добавляем новый диалог в список
        }

        // Здесь устанавливаем activeDialogId на идентификатор найденного диалога
        commit('setActiveDialogId', dialogData.id); // Обновляем activeDialogId на реальный id диалога
        commit('setActiveDialog', dialogData); // Обновляем выбранный диалог
    
        console.log("Диалог найден и выбран:", dialogData);

        // Запрашиваем диалоги с сервера для синхронизации

      } catch (err) {
        console.error("Ошибка при получении диалога:", err);
    
        if (err.response && err.response.status === 404) {
          // Логика создания нового диалога, если диалог не найден
          const createResponse = await dialogsApi.createDialog(recipientId);
          if (createResponse.data) {
            const newDialogData = createResponse.data;
            
            // Добавляем новый созданный диалог в локальный список
            commit('setDialogs', [...state.dialogs, newDialogData]);

            // Устанавливаем activeDialogId для нового диалога
            commit('setActiveDialogId', newDialogData.id);
            commit('selectDialog', newDialogData.id);
    
            console.log("Новый диалог создан и выбран:", newDialogData);

            // Запрашиваем обновленный список диалогов с сервера
          }
        } else if (err.response && err.response.status === 400) {
          console.error('Ошибка создания диалога:', err.response.data.message);
        } else {
          console.error('Ошибка при создании диалога:', err);
        }
      }
    }
,    

    async handleIncomingMessage({ commit, state }, message) {
      const activeDialogId = state.activeDialogId;
      console.log('Получено новое сообщение:', message);

      if (message.dialogId  !== activeDialogId) {
        console.log(`Сообщение относится к неактивному диалогу ${message.dialogId}. Увеличение счетчика непрочитанных.`);

        // Если диалог не активен, увеличиваем счётчик непрочитанных сообщений
        commit('increaseUnreadCount', message.dialogId);
      } else {
        console.log(`Сообщение относится к активному диалогу ${message.dialogId}. Обновление lastMessage и сброс непрочитанных.`);

        // Если диалог активен, добавляем сообщение как новое и сбрасываем счетчик
        commit('updateLastMessage', { dialogId: message.dialogId, message });
        commit('setNewMessage', message);
        commit('resetUnreadCount', activeDialogId);
      }
    },
   
    async sendMessage( messageDto ) {
      try {
        const response = await dialogsApi.sendMessage(messageDto);
        console.log('Сообщение отправлено:', response.data);
      } catch (error) {
        console.error('Ошибка при отправке сообщения:', error);
      }
    },
    

    // Получаем все диалоги
    async fetchDialogs({ commit }) {
      try {
        console.log('Отправка запроса на получение диалогов');
        
        // Отправка запроса к API для получения списка всех диалогов пользователя
        const response = await dialogsApi.getDialogs();
        console.log('Ответ от API:', response);

        // Если список диалогов пуст, выходим из функции
        if (response.data?.content?.length === 0) {
          console.log('Диалоги не найдены');
          return;
        }

        // Логируем каждый диалог и его `lastMessage`
        const data = response.data.content;
        data.forEach(dialog => {
          console.log(`Диалог ${dialog.id}: последнее сообщение`, dialog.lastMessage);
        });

        // Передаем данные мутации, чтобы обновить список диалогов в Vuex
        commit('setDialogs', data);
      } catch (err) {
        // В случае ошибки выводим её в консоль
        console.log(err);
        console.error('Ошибка при запросе списка диалогов:', err);

      }
    },

    async apiUnreadedMessages({ commit, dispatch }) {
      const { data } = await dialogsApi.unreadedMessages();
      console.log('Непрочитанные сообщения с сервера:', data); // Добавь лог для проверки
      commit('setUnreadedMessages', data);
      await dispatch('fetchDialogs');
    },

    async markReadedMessages({ dispatch }, id) {
      await dialogsApi.markReaded(id);
      await dispatch('fetchDialogs');
    },

    async loadLastMessages({ commit, state }, payload) {
      const { countPage, direction } = payload;
      const id = state.activeDialogId; // Используем activeDialogId, а не recipientId
    
      console.log(`Запрос сообщений для диалога ${id}, страница ${countPage}, направление ${direction}`);
    
      try {
          const response = await dialogsApi.getMessages(id, countPage, direction);
          console.log('Ответ от API сообщений:', response);
  
          if (!response.data.content) return;
          
          const lastMessages = response.data;
          commit('clearLastMessages');
          commit('addLastMessages', {
              lastMessages,
              total: response.unreadCount,
          });
      } catch (error) {
        console.error("Ошибка при загрузке сообщений для диалога:", id, error);
      }
    },

    async loadNextMessages({ commit }, payload) {
      const { id, countPage, direction } = payload;

      const response = await dialogsApi.getMessages(id, countPage, direction);
      if (!response.data.content) return;
      const nextMessages = response.data.content;
      commit('addNextMessages', {
        nextMessages,
        total: response.unreadCount,
      });
    },
  },
};