<template>
  <div class="post-reactions">
    <div class="post-reactions__likes">
      <span
        v-for="(react, index) in localReactionsInfo"
        :key="index"
        :id="react.reactionType"
        @click="handleReaction(react.reactionType)"
        class="post-reactions__selected"
        :class="{
          selected: react.reactionType === isSelect,
          added: addedClass,
          deleted: react.reactionType !== isSelect,
        }"
      >
        <img
          v-if="react.reactionType"
          :src="`/static/img/post-reactions/${react.reactionType}.gif`"
          :alt="react.reactionType"
          ref="reactionImg"
        />
        <span class="post-reactions__quantity">
          {{ react.count }}
        </span>
      </span>
    </div>
    <div class="post-reactions__heart">
      <div class="post-reactions__selected" @mouseover="showReactions = true">
        <like-icon /><b class="like-icon__add">+</b>
      </div>
      <div
        class="post-reactions__reactions"
        @mouseleave="showReactions = false"
      >
        <div
          v-for="(reaction, index) in reactions"
          :key="index"
          class="post-reactions__reaction"
          @click="handleReaction(reaction.type)"
        >
          <img
            :title="reaction.label"
            :src="`/static/img/post-reactions/${reaction.type}.gif`"
            :alt="reaction.label"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="js">
/* eslint-disable */
import {computed, reactive, ref} from "vue";
import LikeIcon from "@/Icons/LikeIcon.vue";

const props = defineProps({
  active: null,
  reactionsInfo: {
    type: Array,
    default: () => [],
  },
  quantity: {
    type: Number,
    required: true,
  },
  reaction: String,
})

const reactions = reactive([
  { type: "heart", label: "Нравится" },
  { type: "funny", label: "Смешно" },
  { type: "wow", label: "Ого!" },
  { type: "delight", label: "Восторг" },
  { type: "sadness", label: "Печаль" },
  { type: "malice", label: "&$#%!" },
]);

const emits = defineEmits(['reaction-added', 'reaction-deleted'])

const showReactions = ref(false);
const selectedReaction = ref(null);
const localReactionsInfo = ref(
    JSON.parse(JSON.stringify(props.reactionsInfo) || [{}])
);
const currentSelected = ref(props.reaction);
const addedClass = ref(false);
const deletedClass = ref(false);
const isSelect = computed(() => currentSelected.value);
const isLoading = ref(false);

const increaseReactionsCount = (reaction) => {
  setTimeout(() => {
    addedClass.value = true;
    if (localReactionsInfo.value) {
      if (localReactionsInfo.value.some(obj => obj.reactionType === reaction)) {
        const react = localReactionsInfo.value.find(obj => obj.reactionType === reaction);
        react.count++;
      } else {
        localReactionsInfo.value.push({
          reactionType: reaction,
          count: 1
        })
      }
    } else {
      localReactionsInfo.value = [{
        reactionType: reaction,
        count: 1
      }]
    }
  }, 300)
}

const decreaseReactionsCount = (reaction) => {
  setTimeout(() => {
    if (localReactionsInfo.value.some(obj => obj.reactionType === reaction)) {
      const react = localReactionsInfo.value.find(obj => obj.reactionType === reaction);
        localReactionsInfo.value.splice(localReactionsInfo.value.indexOf(reaction), 1);
      if (!react.count) {
        localReactionsInfo.value.splice(localReactionsInfo.value.indexOf(reaction), 1);
      }
    }
  }, 300)
}

const handleReaction = (reactionName) => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;

  selectedReaction.value = reactionName;
  if (currentSelected.value) {
    switch (currentSelected.value) {
      case selectedReaction.value:
        deletedClass.value = true;
        decreaseReactionsCount(currentSelected.value);
        deleteReaction().then(() => {
          isLoading.value = false;
        });
        currentSelected.value = null;
        break;
      default:
        deleteReaction().then(() => {
          decreaseReactionsCount(currentSelected.value);
          addReaction(reactionName).then(() => {
            increaseReactionsCount(reactionName);
            isLoading.value = false;
          });
        });
    }
  } else {
    addReaction(reactionName).then(() => {
      increaseReactionsCount(reactionName);
      isLoading.value = false;
    });
  }
};

const deleteReaction = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      deletedClass.value = false;
    }, 300);
      emits('reaction-deleted');
      resolve();
  });
};

const addReaction = (reactionName) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      addedClass.value = false;
    }, 300);
      currentSelected.value = reactionName;
      emits('reaction-added', reactionName);
      resolve();
  });
};

</script>

<style lang="stylus">
@import '@/assets/stylus/base/vars.styl'

.post-reactions
  display flex
  align-items center
  cursor pointer
  height 32px
  padding 4px 4px
  transition all .2s ease-in-out
  gap 5px

.post-reactions__label
  font-size 10px

.post-reactions__heart
  position relative
  cursor pointer

.post-reactions__reactions
  position absolute
  bottom -1px
  left -10px
  background-color #fff
  padding 5px
  border-radius 16px
  box-shadow 0px 2px 8px rgba(0,0,0,0.3)
  margin-left 10px
  display flex
  flex-direction column-reverse
  gap 5px
  width 100%
  opacity 0
  visibility: hidden

.post-reactions__heart .post-reactions__reactions
  transition all .3s ease-in-out

.post-reactions__heart:hover .post-reactions__reactions
  visibility: visible
  opacity 1

.post-reactions__reaction
  display flex
  flex-direction column
  align-items center
  cursor pointer
  transition all .2s ease-in-out
  &:hover
    img
      transition all .2s ease-in-out
      transform scale(1.3)

.added
  -webkit-animation: grow .3s;
  animation: grow .3s;
.deleted
  -webkit-animation: shrink .3s;
  animation: shrink .3s;

@-webkit-keyframes grow
  0%
    -webkit-transform: scale(0.0, 0.0)
    transform: scale(0.0, 0.0)
  25%
    -webkit-transform: scale(0.5, 0.5)
    transform: scale(0.5, 0.5)
  50%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)
  75%
    -webkit-transform: scale(1.3, 1.3)
    transform: scale(1.3, 1.3)
  100%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

@keyframes grow
  0%
    -webkit-transform: scale(0.0, 0.0)
    transform: scale(0.0, 0.0)
  25%
    -webkit-transform: scale(0.5, 0.5)
    transform: scale(0.5, 0.5)
  50%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)
  75%
    -webkit-transform: scale(1.3, 1.3)
    transform: scale(1.3, 1.3)
  100%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

@-webkit-keyframes shrink
  0%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

  25%
    -webkit-transform: scale(1.3, 1.3)
    transform: scale(1.3, 1.3)

  50%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

  75%
    -webkit-transform: scale(0.5, 0.5)
    transform: scale(0.5, 0.5)

  100%
    -webkit-transform: scale(0.0, 0.0)
    transform: scale(0.0, 0.0)

@keyframes shrink
  0%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

  25%
    -webkit-transform: scale(1.3, 1.3)
    transform: scale(1.3, 1.3)

  50%
    -webkit-transform: scale(1.0, 1.0)
    transform: scale(1.0, 1.0)

  75%
    -webkit-transform: scale(0.5, 0.5)
    transform: scale(0.5, 0.5)

  100%
    -webkit-transform: scale(0.0, 0.0)
    transform: scale(0.0, 0.0)

.post-reactions__reaction img
  width 40px
  height 30px

.post-reactions__likes
  display flex
  align-items center
  margin-right 20px
  gap 5px

.post-reactions__quantity
  font-size 15px
  font-weight 500
  color #818c99

.post-reactions__selected
  padding 5px 10px
  background-color #F0F2F5
  border-radius 32px
  &:hover
    color ui-cl-color-wild-watermelon
    img
      transition all .2s ease-in-out
      transform scale(1.3)

.post-reactions__selected img
  width 24px
  height 24px

.selected
  background-color ui-cl-color-light-eucalypt
  &:hover
    background-color ui-cl-color-eucalypt

.selected .post-reactions__quantity
  color white

.post-reactions
  &--wow,
  &--funny,
  &--delight
    background-color #FFF2D6
  &--sadness
    background-color #EDF3FA
  &--malice
    background-color #FDEBE8
  &--heart
    background-color #FFEDED

  .like-icon__add
    color #818c99
</style>
