// \src\requests\dialogs.js

import axios from 'axios';
  
export default {
  // Запрашивает сервер для получения (или создания) нового диалога с указанным пользователем recipientId
  newDialogs(recipientId) {
    return axios.get(`/dialogs/recipientId/${recipientId}`);
  },
  // Получает сообщения для диалога dialogId с пагинацией (countPage) и направлением сортировки (direction, например asc или desc)
  getMessages(dialogId, countPage, direction) {
    console.log(`[API] Запрос сообщений для диалога ${dialogId}, страница ${countPage}, направление ${direction}`);
    return axios.get(`dialogs/messages?dialogId=${dialogId}&page=${countPage}&sort=time,${direction}`);
  },

  // Получает список всех диалогов для пользователя, сортированных по количеству непрочитанных сообщений (unreadCount)
  getDialogs() {
    return axios.get('/dialogs?page=0&sort=unreadCount,desc');
  },

  // Получает количество непрочитанных сообщений.
  unreadedMessages() {
    return axios.get('/dialogs/unread');
  },

  // Помечает все сообщения в диалоге dialogId как прочитанные.
  markReaded(dialogId) {
    return axios.put(`dialogs/${dialogId}`);
  },

  // Создает новый диалог с пользователем recipientId.
  createDialog(recipientId) {
    return axios.post('/dialogs', { recipientId });
  },

  // Отправляет сообщение messageDto в диалог dialogId.
  sendMessage(dialogId, messageDto) {
    console.log('Отправка сообщения:', messageDto);
    return axios.post(`/dialogs/${dialogId}/messages`, messageDto)
      .then(response => console.log('Ответ от сервера:', response))
      .catch(error => console.error('Ошибка при отправке сообщения:', error));
  }

  
};
