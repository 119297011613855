<template>
  <icon-box width="22" height="22" viewBox="0 0 48 48" fill="none">
    <g id="SVGRepo_iconCarrier">
      <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
      <path d="M15 8C8.92487 8 4 12.9249 4 19C4 30 17 40 24 42.3262C31 40 44 30 44 19C44 12.9249 39.0751 8 33 8C29.2797 8 25.9907 9.8469 24 12.6738C22.0093 9.8469 18.7203 8 15 8Z" :fill="fill" :stroke="stroke" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </icon-box>
</template>

<script>
import IconBox from "./IconBox.vue";

export default {
  components: { IconBox },
  props: {
    fill: {
      type: String,
      default: () => 'none',
    },
    stroke: {
      type: String,
      default: () => '#818c99',
    },
  }
};
</script>
