<!-- eslint-disable max-len -->
<template>
  <icon-box width="21" height="21" viewBox="0 0 10 10" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M8.26339 4.07739L9.3144 3.02638C9.63351 2.7073 9.79303 2.54776 9.87829 2.37566C10.0406 2.04821 10.0406 1.66377 9.87829 1.33632C9.79303 1.16422 9.63351 1.00467 9.3144 0.685597C8.99535 0.366519 8.83577 0.206983 8.66366 0.121697C8.33625 -0.0405657 7.95177 -0.0405657 7.62436 0.121697C7.45225 0.206983 7.29273 0.366519 6.97362 0.685597L5.90939 1.74987C6.4734 2.71579 7.28459 3.52076 8.26339 4.07739ZM5.05822 2.60101L1.03764 6.62158C0.788895 6.87035 0.664523 6.9947 0.582748 7.14749C0.500979 7.30029 0.466482 7.47274 0.397493 7.81772L0.037364 9.61836C-0.00156312 9.813 -0.0210266 9.91032 0.0343387 9.96568C0.0897039 10.021 0.187022 10.0015 0.381663 9.96263L2.1823 9.6025C2.52725 9.53351 2.69971 9.49904 2.85251 9.41723C3.0053 9.33548 3.12967 9.21112 3.37842 8.96236L7.41006 4.93072C6.46105 4.33639 5.65834 3.53917 5.05822 2.60101Z"
      fill="#BDCDD6" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
