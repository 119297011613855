// C:\Users\Timur\vsCodeProjects\frontend-vue3\src\store\auth\api.js

import axios from 'axios'; // Не забываем импортировать axios

export default {
  namespaced: true,
  state: {
    status: '',
  },
  
  getters: {
    isAuthenticated: (state) => state.isAuthenticated, // Проверка состояния
    authStatus: (state) => state.status,
  },
  
  mutations: {
    setIsAuthenticated(state) {
      const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');
        acc[key] = value;
        return acc;
      }, {});
  
      state.isAuthenticated = !!cookies.accessToken; // Проверка наличия accessToken
    },
    setStatus(state, status) {
      state.status = status;
    }
  },

  actions: {
    async initializeAuth({ commit }) {
      commit('setIsAuthenticated'); // Восстанавливаем статус авторизации при старте приложения
    },
    // Пример авторизации
    async login({ commit }, user) {
      commit('setStatus', 'loading');
      try {
        await axios.post('/auth/login', user, { withCredentials: true });
        commit('setStatus', 'success');
        commit('setIsAuthenticated'); // Обновление состояния аутентификации
        console.log('Cookies after login:', document.cookie);
        return { success: true }; // Возвращаем успешный результат
      } catch (error) {
        commit('setStatus', 'error');
        console.error('Login failed', error);
        return { success: false, error: error.response.data.message || 'Login failed' };
      }
    },

    async logout({ commit }) {
      console.log('api.js: Начало работы экшена logout');
      try {
        await axios.post('/auth/logout', {}, { withCredentials: true });
        // Очищаем куки на фронтенде
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        commit('setStatus', 'logout');
        commit('setIsAuthenticated', false);  // Устанавливаем статус isAuthenticated в false

      } catch (error) {
        console.error('Ошибка при logout', error);
      }
    },

    async refreshToken({ commit }) {
      try {
        // Выполняем POST-запрос для обновления токена
        await axios.post('/auth/refresh', {}, { withCredentials: true });
        commit('setStatus', 'success');
      } catch (error) {
        console.error('Token refresh failed', error);
        commit('setStatus', 'error');
      }
    },
  }
};

