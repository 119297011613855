<!-- eslint-disable max-len -->
<!-- eslint-disable max-attributes-per-line -->
<template>
  <icon-box width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1"
    stroke-linecap="square" stroke-linejoin="miter" color="currentColor">
    <circle cx="12" cy="12" r="10" />
    <path stroke-linecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,
      4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z" />
    <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
