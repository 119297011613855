<!-- eslint-disable max-len -->
<template>
  <icon-box width="17" height="17" viewBox="0 0 17 17" fill="currentColor">
    <path
      d="M17 12.1887C17 13.2509 16.1196 14.1132 15.0352 14.1132H1.96477C0.879724 14.1132 0 13.252 0 12.1887C0 11.1268 0.878986 10.2642 1.96222 10.2642C1.96095 10.2642 1.96154 6.41485 1.96154 6.41485C1.96154 2.87111 4.88846 0 8.5 0C12.1116 0 15.0385 2.87179 15.0385 6.41485V10.2636C16.1194 10.2642 17 11.1264 17 12.1887ZM15.0378 11.5472C14.3152 11.5472 13.7308 10.974 13.7308 10.2636V6.41485C13.7308 3.58037 11.3893 1.28302 8.5 1.28302C5.61062 1.28302 3.26923 3.57976 3.26923 6.41485V10.2636C3.26923 10.972 2.68335 11.5472 1.96222 11.5472C1.60128 11.5472 1.30769 11.8353 1.30769 12.1887C1.30769 12.5428 1.60128 12.8302 1.96477 12.8302H15.0352C15.3979 12.8302 15.6923 12.5418 15.6923 12.1887C15.6923 11.8356 15.3978 11.5472 15.0378 11.5472ZM5.55769 14.1132H6.86538C6.86538 14.9989 7.59723 15.717 8.5 15.717C9.40277 15.717 10.1346 14.9989 10.1346 14.1132H11.4423C11.4423 15.7075 10.125 17 8.5 17C6.87501 17 5.55769 15.7075 5.55769 14.1132Z" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>

<style lang="stylus">

@keyframes slidein
  from
    transform scale(1)

  50%
    transform scale(1.2)
  to
    transform scale(1)
</style>
