// C:\Users\Timur\vsCodeProjects\frontend-vue3\src\store\auth\index.js
 
import info from './info';
import api from './api';
import languages from './languages';
import captcha from './captcha';

export default {
  namespaced: true,
  modules: {
    info,
    api,
    languages,
    captcha,
  },
};
 