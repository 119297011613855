// src\utils\lang.js

export default {
  rus: {
    acknowledged: 'Уведомлен(а)',

    errorPrivacyPolicyNotAccepted: 'Пожалуйста, прочитайте Политику конфиденциальности и Передачу персональных данных.',
    errorInvalidEmail: 'Неверный формат Email.',
    errorPasswordTooShort: 'Пароль должен содержать не менее 8 символов.',
    errorPasswordsDoNotMatch: 'Пароли должны совпадать.',

    privacyPolicyTitle: 'Политика конфиденциальности',
    privacyPolicyContent: `
      **1. Общие положения:** <br>
      Настоящая политика конфиденциальности объясняет, какие данные мы собираем, как они обрабатываются, и для каких целей. Мы обязуемся защищать вашу конфиденциальность и гарантировать безопасность ваших данных.
      <br><br>
      **2. Какие данные мы собираем:** <br>
      Мы можем собирать идентификационные данные, контактные данные, информацию о действиях на сайте, а также данные о вашем устройстве и браузере. Эти данные помогают нам улучшать наш сервис.
      <br><br>
      **3. Использование данных:** <br>
      Ваши данные используются для предоставления персонализированных услуг, анализа использования сайта, а также для улучшения функционала. Мы можем применять данные для маркетинговых целей и таргетирования рекламы.
      <br><br>
      **4. Передача данных третьим лицам:** <br>
      Мы передаем данные только доверенным партнерам для аналитики, маркетинга и поддержки функциональности сайта. Все наши партнеры обязаны соблюдать конфиденциальность и безопасность передаваемых данных.
      <br><br>
      **5. Ваши права:** <br>
      Вы имеете право запросить доступ к своим данным, изменить или удалить их, а также ограничить их обработку. Если вы хотите воспользоваться этими правами, свяжитесь с нами.
      <br><br>
      **6. Защита данных:** <br>
      Мы применяем современные технологии для защиты ваших данных от несанкционированного доступа. Ваши данные хранятся на защищенных серверах, и мы используем протоколы шифрования для их передачи.
      <br><br>
      **7. Связь:** <br>
      Если у вас возникли вопросы по поводу этой политики или вы хотите узнать больше о своих правах, напишите нам на support@himetrik.com.
    `,

    personalDataTransferTitle: 'Передача персональных данных',
    personalDataTransferContent: `
      **Какие данные мы собираем:** <br>
      Мы собираем следующие данные: ваши контактные данные, данные для аутентификации, информацию об устройстве, данные о местоположении и действиях на платформе. Это позволяет нам предоставлять качественные услуги и персонализировать контент для вас.
      <br><br>
      **Как мы используем данные:** <br>
      Ваши данные могут быть использованы для анализа использования платформы, персонализации интерфейса, улучшения функциональности, а также для предоставления рекламы и предложений, релевантных вашим интересам.
      <br><br>
      **Передача данных третьим лицам:** <br>
      Мы передаем данные проверенным партнерам для выполнения аналитики, маркетинговых исследований и обеспечения функциональности сайта. Наши партнеры обязаны соблюдать конфиденциальность и не использовать данные для других целей.
      <br><br>
      **Ваши права:** <br>
      Вы можете запросить удаление, обновление или ограничение обработки данных, а также отозвать согласие на их использование. Мы придерживаемся всех законодательных требований по защите персональных данных.
      <br><br>
      **Защита данных:** <br>
      Мы применяем современные методы защиты, чтобы предотвратить несанкционированный доступ к вашим данным, и обеспечиваем, что все передачи данных происходят по защищенным каналам.
      <br><br>
      Если у вас есть вопросы, свяжитесь с нами по адресу support@himetrik.com.
    `,

    support: 'Поддержка',
    langSelect: 'Язык: ',
    lang: 'Русский',
    themeTitle: 'Тема',
    loginAuth: 'Войдите в аккаунт',
    loginAuthBtn: 'Войти',
    loginAuthForgot: 'Забыли пароль?',
    enterEmail: 'Введите Email',
    correctEmail: 'Введите корректный Email',
    passLogin: 'Пароль',
    passLoginEnter: 'Введите пароль',
    validatePass1: 'Пароль должен быть не менее',
    validatePass2: 'символов. Сейчас',
    verifyEmailTitle: 'Подтверждение email',
    verificationCodeError: 'Код должен быть от 6 символов',
    verifyEmailBtn: 'Проверить',
    passwordComplexityMessage: 'Пароль должен содержать минимум одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.',
    passInfo: 'Пароль должен состоять из латинских букв, цифр и знаков. Обязательно содержать одну заглавную букву, одну цифру и состоять из 8 символов.',
    passInfoChange: 'Пароль должен содержать не менее 8 символов.',
    passLevelFirst: 'слабый',
    passLevelSecond: 'средний',
    passLevelTree: 'надёжный',
    repeatPassword: 'Повторите пароль',
    matchPassword: 'Пароли должны совпадать',
    checkingCredentialsMessage: 'Checking credentials, please wait...',

    createAccTitle: 'Создание аккаунта',
    createAccStepBtn: 'Дальше',
    createAccPersonalTitle: 'Личные данные',
    createAccCaptchaDescr: 'Введите символы, которые вы видите на экране',
    createAccCaptchaRefresh: 'Обновить',
    createAccBtnReg: 'Зарегистрироваться',
    createAccBtnBack: 'Назад',
    createAccNameField1: 'Имя',
    createAccNameField2: 'Фамилия',
    requiredField: 'Обязательное поле',
    minimumNumberOfCharacters: 'Минимальное количество символов',
    codeTitle: 'Код',
    codeNoMatch: 'код не совпадает',
    codeMin6: 'код должен быть от 6 символов',
    confirmComponent1: 'Я согласен с ',
    confirmComponent2: 'полит. конф-ти',
    confirmComponent3: ' и передачей ',
    confirmComponent4: 'перс. данных',
    recoveryPassTitle: 'Восстановление пароля',
    recoveryPassDescription: 'Укажите, куда отправить инструкции для восстановления пароля.',
    recoveryPassBtn: 'Отправить',
    setNewPasswordTitle: 'Новый пароль',
    sendingCodeMessage: 'Отправка кода на почту, пожалуйста подождите...',

    sidebarMyPage: 'Моя страница',
    sidebarFriend: 'Друзья',
    sidebarMessage: 'Сообщения',
    sidebarNews: 'Новости',
    sidebarSetting: 'Настройки',

    messageStatusLongAgo: 'был(а) в сети давно',
    messageStatusWasOnline: 'был(а) в сети',
    messageHistoryIsFinal: 'Больше сообщений нет',
    messageInputPlaceholder: 'Ваше сообщение...',
    messageDialogNotSelected: 'Диалог не выбран',
 
    yes: 'Да',
    cancel: 'Отмена',

    registerSucces: 'Успешно!',
    searchPlaceholder: 'Поиск',
    loading: 'Загрузка...',
    logout: 'Выйти',

    languageTitle: 'Язык',
    languageCurrentLabelEn: 'Английский',
    languageCurrentLabelRu: 'Русский',

    notNotification: 'Уведомлений нет',
    showmoreNotification: 'Показать еще',
    readedNotification: 'Отметить всё как прочитанное',

    recommendBlockTitle: 'Возможно вы их знаете',
    recommendBlockDescription: 'В данный момент нет вариантов. Для получения рекомендаций, добавьте несколько друзей.',
    recommendBlockBtn: 'Искать друзей',
    recommendBlockAdd: 'Добавить',

    profileSharedAccTitle: 'Поделиться:',
    profileMyPublishText: 'Мои публикации',
    profileMyPublishNoMe: 'Публикации',
    profileNotFilled: 'не заполнено',
    profileNotFilledAll: 'профиль не заполнен',
    profileEditingText: 'Редактировать профиль',
    profileActionsUser: 'Действия',
    profileAccountUnsubscribe: 'Отписаться',
    profileAccountSubscribe: 'Подписаться',
    profileAccountAcceptRequests: 'Принять запрос',
    profileAccountSendMessage: 'Написать сообщение',
    profileAccountUnblocking: 'Разблокировать',
    profileAccountBlocking: 'Заблокировать',
    profileAccountDeleteFriend: 'Удалить из друзей',
    profileAccountCancelFriend: 'Отменить заявку',
    profileAccountAddFriend: 'Добавить в друзья',
    profileInfoBirthday: 'Дата рождения:',
    profileInfoPhone: 'Телефон:',
    profileInfoCountry: 'Страна, город:',
    profileInfoAbout: 'О себе:',
    profileInfoStatusOnline: 'онлайн',
    profileInfoStatusOffline: 'оффлайн',

    profileEmojiStatus1: 'эксперт в программировании',
    profileEmojiStatusDisclamer1: `Настоящий гений в программировании! Он решает задачи быстрее, чем ты успеваешь выдохнуть.
    Его код - это как шедевр искусства, только вместо кисти он использует клавиатуру.
    Работать с ним - это как играть в шахматы с компьютером, только он не сливается в конце.`,
    profileEmojiStatus2: 'новичок в программировании',
    profileEmojiStatusDisclamer2: 'Настолько гениален в программировании, что способен написать код на языке, который только он сам придумал.',
    profileEmojiStatus3: 'гениальный экспериментатор',
    profileEmojiStatusDisclamer3: `Каждый его код - это как произведение искусства, только вместо кистей он использует клавиатуру.
    Работать с ним - это как играть в шахматы с компьютером, только он всегда остается на шаг впереди, а ты чувствуешь себя как старенький Windows XP.`,
    profileEmojiStatus4: 'преподаватель в программировании',
    profileEmojiStatusDisclamer4: `Если бы программа была пирогом, то этот преподаватель был бы шеф-поваром.`,
    profileEmojiStatus5: 'фрилансер по жизни',
    profileEmojiStatusDisclamer5: `Он не останавливается на достигнутом, он постоянно ищет новые способы заработка на своем искусстве,
    чтобы оплатить свои долги по кредитке.`,
    profileEmojiStatus6: 'любит программирование',
    profileEmojiStatusDisclamer6: `В его жизни нет места для скучных проектов и поверхностного подхода - только глубокий анализ
    и творческий подход к каждой задаче. Если у тебя есть какой-то нудный проект, то лучше держись от этого парня подальше - он точно
     не захочет с тобой иметь дело!`,

    profileBlockMyPublished: 'Опубликованные',
    profileBlockMyQueued: 'Запланированные',

    weatherHumidity: 'Влажность',
    weatherWind: 'Ветер',
    weatherMinTemp: 'Мин. температура:',
    weatherMaxTemp: 'Макс. температура:',
    weatherLoading: 'Загружаем данные с погодой...',

    friendsTitle: 'Мои друзья',
    friendsSearchPlaceholder: 'Начните вводить имя друга...',
    friendsRequestsFrom: 'Запросы в друзья',
    friendsRequestsTo: 'Исходящие запросы',
    friendsMySubscribe: 'Подписчики',
    friendsBlocked: 'Заблокированные',
    friendsMyPesonalSubscribed: 'Подписан(а)',
    friendsSearchResultClear: 'Сбросить',
    friendsSearchDescription: 'Результаты поиска по запросу',
    friendsShowMore: 'Загрузить ещё...',
    friendsNoResult: 'Вы никому не отправляли заявку в друзья,' +
    ' самое время начать общаться.',
    friendsNoActiveTab: 'Выберите одну из доступных вкладок c друзьями.',

    friendsBlockEdit: 'Редактировать',
    friendsBlockDelete: 'Удалить из списка',

    friendsModalLastText: ' из друзей?',
    friendsModalLastTextSecond: ' из списка модераторов?',
    friendsModalTextDelete: 'Вы уверены, что хотите удалить пользователя ',
    friendsModalTextDeleteSub: 'Вы уверены, что хотите отписаться от пользователя ',
    friendsModalTextCancelFriend: 'Вы уверены, что хотите отменить запрос в дружбу с ',
    friendsModalTextDeteleModerator: 'Вы уверены, что хотите удалить ',
    friendsModalTextBlocked: 'Вы уверены, что хотите заблокировать пользователя ',
    friendsModalTextUnlock: 'Вы уверены, что хотите разблокировать пользователя ',

    friendsFindTitle: 'Найти друзей',
    friendsFindFindFirstText: 'Найдено',
    friendsFindFindSecondText: 'человек',

    friendsFindParamsTitle: 'Параметры поиска',
    friendsFindYearTitle: 'Возраст:',
    friendsFindFrom: 'От',
    friendsFindTo: 'До',
    friendsFindRegion: 'Регион:',
    friendsFindCountry: 'Страна',
    friendsFindCity: 'Город',
    friendsFindCheckbox: 'Поиск по всем пользователям',

    newsAddPlaceholder: 'Поделитесь новостью...',
    newsAddTitle: 'Дайте тему',
    newsAddSettings: 'Настройка публикации',
    newsAddSettingsTimePublished: 'Запланированное время',
    newsAddQueued: 'Запланировать',
    newsAddPosted: 'Опубликовать',
    newsAddQueuedTimeToPosted: 'Публикация будет опубликована:',
    newsAddDataPickedData: 'Выберите дату',
    newsAddDataPickedTime: 'Выберите время',
    newsAddDataPickedButtonSelect: 'Выбрать',
    newsAddDataPickedButtonClose: 'Закрыть',

    newsTagPlaceholder: 'Начните вводить...',

    newsBlockPublishedTitle: 'опубликован',
    newsBlockEditedTime: 'изменен',
    newsBlockDeleted: 'Удалить',
    newsBlockQueuedTime: 'Дата и время публикации:',
    newsBlockQueuedDatatime: 'будет опубликован',
    newsBlockReadedMore: 'Читать далее',
    newsBlockReadedHide: 'Скрыть',
    newsBlockReadedTime: 'мин.',
    newsBlockReadedDisclamer: 'Примерно столько времени вы потратите на прочтение этой статьи',
    newsBlockCommentTitleFirst: 'Посмотреть комментарии',
    newsBlockCommentTitleSecond: 'Скрыть комментарии',
    newsBlockCommentMore: 'Ещё комментарии...',
    newsStepFirst: `Заголовок должен быть привлекательным и интересным.
    Он должен содержать ключевые слова, которые будут отражать суть новости, и быть лаконичным, чтобы быстро захватить внимание.
    Также важно использовать заголовки, которые вызывают эмоции, такие как любопытство, удивление или волнение,
    чтобы привлечь внимание читателей.`,
    newsStepSecond: `Тело новости должно быть информативным, логичным и увлекательным.
    Оно должно содержать все необходимые детали и факты, которые подтверждают заголовок.
    Важно использовать ясный и простой язык, чтобы читатели могли легко понимать содержание статьи.
    Также стоит использовать креативные подзаголовки, чтобы разбить текст на более мелкие части и сделать его более доступным для чтения.
    Кроме того, тело новости должно вызывать эмоции и удерживать внимание читателей до конца статьи.`,
    newsStepThird: `Используйте уже существующие тэги, если они соответствуют содержанию вашей статьи.
    Для этого начните вводить слово в поле и выберите нужный тэг из списка.
    Если нужного тэга нет в списке, добавьте новый, но придерживайтесь ограничения в 10 символов.
    Название тэга должно быть кратким, но информативным.`,
    newsStepFour: `Вы можете запланировать новость на любое доступное время, чтобы она была опубликована автоматически.
    Пожалуйста, убедитесь, что время публикации не в прошлом времени.
    Вы также можете отслеживать запланированные новости в своем профиле и редактировать их содержимое при необходимости.`,

    commentAddPlaceholderFirst: 'Написать ответ...',
    commentAddPlaceholderSecond: 'Написать комментарий...',
    commentAnswerShow: 'показать',
    commentAnswerTextFirst: 'ответа',
    commentAnswerTextSecond: 'ответ',
    commentIsDeleted: 'Комментарий удален.',
    commentIsRecovery: 'Восстановить',
    commentAddAnswer: 'Ответить',

    searchNewsAuthor: 'Автор:',
    searchNewsTag: 'Название Тег:',
    searchNewsInputPlaceholder: 'Введите автора',
    searchNewsTimePost: 'Время публикации:',
    searchNewsAllTime: 'За все время',
    searchNewsLastYear: 'За последний год',
    searchNewsLastMonth: 'За последний месяц',
    searchNewsLastWeek: 'За последнюю неделю',
    searchNewsBtnComplited: 'Применить',
    searchNewsShowMoreButton: 'Показать фильтры',
    searchNewsCloseMoreButton: 'Скрыть фильтры',

    searchUserNameInput: 'Введите имя',
    searchUserSurnameInput: 'Введите фамилию',
    searchUserTitle: 'Люди',
    searchNewsTitle: 'Новости',
    searchNewsEmpty: 'В данный момент нет новостей для отображения',
    searchUserEmpty: 'В данный момент нет людей для отображения',

    settingBtnChange: 'Изменить',
    settingPasswordLabel: 'Пароль:',
    settingPasswordPlaceholder: 'Введите текущий пароль',
    settingPasswordPlaceholder2: 'Введите новый пароль',

    settingsEmojiDelete: 'Удалить статус',
    settingsUnknowEmojiStatus: 'Изменить эмодзи-статус',
    settingsSetEmojiStatus: 'Выбрать эмодзи-статус',
    settingsEmojiTitle: 'Выберите статус',
    settingsEmojiDisclamer: 'Он будет виден всем справа от вашего имени',

    settingsMainAddCover: 'Добавить обложку',
    settingsMainEditCover: 'Изменить обложку',
    settingsMainEditPhoto: 'Изменить фото',
    settingMainAddPhoto: 'Добавить фото',
    settingMainPhotoAlt: 'Нет фото',
    settingMainPlaceholderPhone: 'Введите телефон',
    settingMainPlaceholderCountry: 'Выберите страну',
    settingMainPlaceholderCity: 'Выберите город',
    settingMainCountry: 'Страна:',
    settingMainCity: 'Город:',
    settingMainUnknow: 'Неизвестно',
    settingMainAbout: 'О себе:',
    settingMainSave: 'Сохранить',
    settingMainCancel: 'Отменить',

    settingDeleteTitle: 'После удаления профиля будет удалена вся связанная с ним информация: друзья, публикации, комментарии, лайки.',
    settingDeleteLabel: 'Да, удалить мою страницу и всю связанную с ней информацию',
    settingDeleteButton: 'Удалить профиль',
    settingDeleteCancel: 'Не удалять профиль, я хочу вернуться',

    settingModalEmailChange: 'Инструкция по смене E-mail отправлена на', //'Адрес электронной почты успешно изменен на'
    settingModalPasswordChange: 'Пароль успешно изменен'
  },

  eng: {
    acknowledged: 'Acknowledged',

    errorPrivacyPolicyNotAccepted: 'Please read the Privacy Policy and Personal Data Transfer Agreement.',
    errorInvalidEmail: 'Invalid Email format.',
    errorPasswordTooShort: 'The password must be at least 8 characters long.',
    errorPasswordsDoNotMatch: 'Passwords must match.',

    privacyPolicyTitle: 'Privacy Policy',
    privacyPolicyContent: `
      **1. General Information:** <br>
      This privacy policy explains what data we collect, how it is processed, and for what purposes. We are committed to protecting your privacy and ensuring the security of your data.
      <br><br>
      **2. Data We Collect:** <br>
      We may collect identification data, contact details, information about activities on the site, as well as data about your device and browser. This data helps us improve our service.
      <br><br>
      **3. Data Usage:** <br>
      Your data is used to provide personalized services, analyze site usage, and improve functionality. We may use data for marketing purposes and ad targeting.
      <br><br>
      **4. Data Sharing with Third Parties:** <br>
      We only share data with trusted partners for analytics, marketing, and site functionality support. All our partners are required to maintain confidentiality and data security.
      <br><br>
      **5. Your Rights:** <br>
      You have the right to request access to your data, modify or delete it, and limit its processing. If you wish to exercise these rights, please contact us.
      <br><br>
      **6. Data Protection:** <br>
      We use modern technologies to protect your data from unauthorized access. Your data is stored on secure servers, and we use encryption protocols for its transfer.
      <br><br>
      **7. Contact:** <br>
      If you have questions about this policy or want to learn more about your rights, please contact us at support@himetrik.com.
    `,

    personalDataTransferTitle: 'Personal Data Transfer',
    personalDataTransferContent: `
      **Data We Collect:** <br>
      We collect the following data: your contact details, authentication data, device information, location data, and activity on the platform. This enables us to provide quality services and personalize content for you.
      <br><br>
      **How We Use Data:** <br>
      Your data may be used to analyze platform usage, personalize the interface, improve functionality, and deliver advertisements and offers relevant to your interests.
      <br><br>
      **Data Sharing with Third Parties:** <br>
      We share data with trusted partners for analytics, marketing research, and site functionality support. Our partners are required to maintain confidentiality and not use the data for other purposes.
      <br><br>
      **Your Rights:** <br>
      You can request data deletion, updating, or restriction of processing, and you can withdraw consent for its use. We comply with all legal requirements for personal data protection.
      <br><br>
      **Data Protection:** <br>
      We apply modern protective methods to prevent unauthorized access to your data and ensure that all data transfers occur over secure channels.
      <br><br>
      If you have any questions, please contact us at support@himetrik.com.
    `,

    support: 'Support',
    langSelect: 'Language: ',
    lang: 'English',
    themeTitle: 'Theme:',
    loginAuth: 'Log in to your account',
    loginAuthBtn: 'Log in',
    loginAuthForgot: 'Forgot your password?',
    enterEmail: 'Enter Email',
    correctEmail: 'Enter the correct Email',
    passLogin: 'Password',
    passLoginEnter: 'Enter the password',
    validatePass1: 'The password must be at least',
    validatePass2: 'characters. Now he',
    verifyEmailTitle: 'Email Verification',
    verificationCodeError: 'Invalid code, please try again',
    verifyEmailBtn: 'Verify',
    passInfo: 'The password must consist of Latin letters, numbers and signs. It must contain one capital letter, one digit and consist of 8 characters.',
    passLevelFirst: 'weak',
    passLevelSecond: 'average',
    passLevelTree: 'reliable',
    passwordComplexityMessage: 'The password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',

    repeatPassword: 'Repeat the password',
    matchPassword: 'Passwords must match',
    checkingCredentialsMessage: 'Идет проверка почты и паролей...',

    createAccTitle: 'Creating an account',
    createAccStepBtn: 'Next',
    createAccPersonalTitle: 'Personal data',
    createAccCaptchaDescr: 'Enter the characters you see on the screen',
    createAccCaptchaRefresh: 'Update',
    createAccBtnReg: 'Register',
    createAccBtnBack: 'Back',
    createAccNameField1: 'Name',
    createAccNameField2: 'Surname',
    requiredField: 'Required field',
    minimumNumberOfCharacters: 'Minimum number of characters',
    codeTitle: 'Code',
    codeNoMatch: 'the code doesnt match',
    codeMin6: 'the code min 6',
    confirmComponent1: 'I agree with ',
    confirmComponent2: 'privacy policy',
    confirmComponent3: ' and transfer ',
    confirmComponent4: 'personal data',
    recoveryPassTitle: 'Password recovery',
    recoveryPassDescription: 'Specify where to send instructions for password recovery.',
    recoveryPassBtn: 'Send',
    setNewPasswordTitle: 'New password',
    sendingCodeMessage: 'Sending code to email, please wait...',

    sidebarMyPage: 'My page',
    sidebarFriend: 'Friends',
    sidebarMessage: 'Messages',
    sidebarNews: 'News',
    sidebarSetting: 'Settings',
    registerSucces: 'Successfully!',
    searchPlaceholder: 'Search',

    languageTitle: 'Language',
    languageCurrentLabelEn: 'English',
    languageCurrentLabelRu: 'Russian',
    
    loading: 'Loading...',
    logout: 'Exit',

    notNotification: 'There are no notifications',
    showmoreNotification: 'Show more',
    readedNotification: 'Mark everything as read',

    recommendBlockTitle: 'Perhaps you know them',
    recommendBlockDescription: 'There are no options at the moment. To get recommendations, add a few friends.',
    recommendBlockBtn: 'Search for friends',
    recommendBlockAdd: 'Add',

    profileSharedAccTitle: 'To share:',
    profileMyPublishText: 'My publications',
    profileMyPublishNoMe: 'Publications',
    profileNotFilled: 'empty',
    profileNotFilledAll: 'profile is not filled',
    profileEditingText: 'Edit profile',
    profileActionsUser: 'Actions',
    profileAccountUnsubscribe: 'Unsubscribe',
    profileAccountSubscribe: 'Subscribe',
    profileAccountAcceptRequests: 'Accept the request',
    profileAccountSendMessage: 'Write a message',
    profileAccountUnblocking: 'Unlock',
    profileAccountBlocking: 'Block',
    profileAccountDeleteFriend: 'Remove from friends',
    profileAccountCancelFriend: 'Cancel the application',
    profileAccountAddFriend: 'Add to friends',
    profileInfoBirthday: 'Date of birth:',
    profileInfoPhone: 'Phone:',
    profileInfoCountry: 'Country, city:',
    profileInfoAbout: 'About me:',
    profileInfoStatusOnline: 'online',
    profileInfoStatusOffline: 'offline',
    profileBlockMyPublished: 'Published',
    profileBlockMyQueued: 'Planned',

    profileEmojiStatus1: 'expert in programming',
    profileEmojiStatusDisclamer1: `A real programming genius! He solves problems faster than you have time to exhale.
    His code is like a masterpiece of art, only instead of a brush he uses a keyboard.
    Working with him is like playing chess with a computer, only he doesn't merge at the end.`,

    profileEmojiStatus2: 'new to programming',
    profileEmojiStatusDisclamer2: 'He is so brilliant in programming that he is able to write code in a language that only he himself invented.',

    profileEmojiStatus3: 'a brilliant experimenter',
    profileEmojiStatusDisclamer3: `His every code is like a work of art, only he uses a keyboard instead of brushes.
    Working with him is like playing chess with a computer, only he always stays one step ahead, and you feel like an old Windows XP.`,

    profileEmojiStatus4: 'teacher in programming',
    profileEmojiStatusDisclamer4: `If the program was a pie, then this teacher would be a chef.`,

    profileEmojiStatus5: 'freelancer for life',
    profileEmojiStatusDisclamer5: `He does not stop there, he is constantly looking for new ways to make money on his art,
    to pay off your credit card debts.`,

    profileEmojiStatus6: 'loves programming',
    profileEmojiStatusDisclamer6: `There is no place in his life for boring projects and a superficial approach - only deep analysis
    and a creative approach to each task. If you have some boring project, then it's better to stay away from this guy - he's for sure
    won't want to deal with you!`,

    weatherHumidity: 'Humidity',
    weatherWind: 'Wind',
    weatherMinTemp: 'Min. temperature:',
    weatherMaxTemp: 'Max. temperature:',
    weatherLoading: 'Uploading weather data...',

    messageStatusLongAgo: 'been online for a long time',
    messageStatusWasOnline: 'was online',
    messageHistoryIsFinal: 'There are no more messages',
    messageInputPlaceholder: 'Your message...',
    messageDialogNotSelected: 'The dialog is not selected',

    yes: 'Yes',
    cancel: 'Cancel',

    friendsTitle: 'My friends',
    friendsSearchPlaceholder: `Start typing a friend's name...`,
    friendsRequestsFrom: 'Friend requests',
    friendsRequestsTo: 'Outgoing requests',
    friendsMySubscribe: 'Subscribers',
    friendsBlocked: 'Blocked',
    friendsMyPesonalSubscribed: 'Signed',
    friendsSearchResultClear: 'Reset',
    friendsSearchDescription: 'Search results for',
    friendsShowMore: 'Show more...',
    friendsNoResult: 'You have not sent a friend request to anyone,' +
    ` it's time to start communicating.`,
    friendsNoActiveTab: 'Select one of the available tabs with friends.',
    friendsBlockEdit: 'Edit',
    friendsBlockDelete: 'Remove from the list',

    friendsModalLastText: ' from friends?',
    friendsModalLastTextSecond: ' from the list of moderators?',
    friendsModalTextDelete: 'Are you sure you want to delete the user ',
    friendsModalTextDeleteSub: 'Are you sure you want to unsubscribe from the user ',
    friendsModalTextCancelFriend: 'Are you sure you want to cancel the friendship request with ',
    friendsModalTextDeteleModerator: 'Are you sure you want to delete ',
    friendsModalTextBlocked: 'Are you sure you want to block the user ',
    friendsModalTextUnlock: 'Are you sure you want to unblock the user ',

    friendsFindTitle: 'Find friends',
    friendsFindFindFirstText: 'Found',
    friendsFindFindSecondText: 'human',

    friendsFindParamsTitle: 'Search parameters',
    friendsFindYearTitle: 'Age:',
    friendsFindFrom: 'From',
    friendsFindTo: 'To',
    friendsFindRegion: 'Region:',
    friendsFindCountry: 'Contry',
    friendsFindCity: 'City',
    friendsFindCheckbox: 'Search by all users',

    newsAddPlaceholder: 'Share the news...',
    newsAddTitle: 'Give me a topic',
    newsAddSettings: 'Setting up a publication',
    newsAddSettingsTimePublished: 'Scheduled time',
    newsAddQueued: 'Schedule',
    newsAddPosted: 'To publish',
    newsAddQueuedTimeToPosted: 'The publication will be published:',
    newsAddDataPickedData: 'Select a date',
    newsAddDataPickedTime: 'Choose a time',
    newsAddDataPickedButtonSelect: 'Select',
    newsAddDataPickedButtonClose: 'Cancel',
    newsTagPlaceholder: 'Start typing...',

    newsBlockPublishedTitle: 'published',
    newsBlockEditedTime: 'changed',
    newsBlockQueuedTime: 'Date and time of publication:',
    newsBlockDeleted: 'Deleted',
    newsBlockQueuedDatatime: 'will be published',
    newsBlockReadedMore: 'Read more',
    newsBlockReadedHide: 'Hide',
    newsBlockReadedTime: 'min.',
    newsBlockReadedDisclamer: `That's about the amount of time you'll spend reading this article`,
    newsBlockCommentTitleFirst: 'View comments',
    newsBlockCommentTitleSecond: 'Hide comments',
    newsBlockCommentMore: 'More comments...',
    newsStepFirst: `The title should be attractive and interesting.
    It should contain keywords that will reflect the essence of the news, and be concise in order to quickly capture attention.
    It is also important to use headlines that evoke emotions such as curiosity, surprise or excitement.,
    to attract the attention of readers.`,
    newsStepSecond: `The body of the news should be informative, logical and fascinating.
    It should contain all the necessary details and facts that confirm the title.
    It is important to use clear and simple language so that readers can easily understand the content of the article.
    It is also worth using creative subheadings to break the text into smaller parts and make it more readable.
    In addition, the body of the news should evoke emotions and hold the attention of readers until the end of the article.`,
    newsStepThird: `Use existing tags if they correspond to the content of your article.
    To do this, start typing a word in the field and select the desired tag from the list.
    If the desired tag is not in the list, add a new one, but stick to the 10-character limit.
    The tag name should be short but informative.`,
    newsStepFour: `You can schedule a news item at any available time so that it is published automatically.
    Please make sure that the publication time is not in the past time.
    You can also track scheduled news in your profile and edit their content if necessary.`,

    commentAddPlaceholderFirst: 'Write an answer...',
    commentAddPlaceholderSecond: 'Write a comment...',
    commentAnswerShow: 'show',
    commentAnswerTextFirst: 'replies',
    commentAnswerTextSecond: 'answer',
    commentIsDeleted: 'The comment has been deleted.',
    commentIsRecovery: 'Recover',
    commentAddAnswer: 'Reply',

    searchNewsAuthor: 'Author:',
    searchNewsInputPlaceholder: 'Enter the author',
    searchNewsTimePost: 'Publication time:',
    searchNewsAllTime: 'For all the time',
    searchNewsLastYear: 'For the last year',
    searchNewsLastMonth: 'For the last month',
    searchNewsLastWeek: 'For the last week',
    searchNewsBtnComplited: 'Apply',
    searchUserNameInput: 'Enter a name',
    searchUserSurnameInput: 'Enter your last name',
    searchUserTitle: 'People',
    searchNewsTitle: 'News',
    searchNewsEmpty: 'There is no news to display at the moment',
    searchUserEmpty: 'There are no people to display at the moment',
    searchNewsShowMoreButton: 'Show filters',
    searchNewsCloseMoreButton: 'Hide Filters',

    settingBtnChange: 'Change',
    settingPasswordLabel: 'Password:',
    settingPasswordPlaceholder: 'Enter the current password',
    settingPasswordPlaceholder2: 'Enter a new password',

    settingsEmojiDelete: 'Delete status',
    settingsUnknowEmojiStatus: 'Change emoji status',
    settingsSetEmojiStatus: 'Choose emoji status',
    settingsEmojiTitle: 'Select the status',
    settingsEmojiDisclamer: 'It will be visible to everyone to the right of your name',

    settingsMainAddCover: 'Add a cover',
    settingsMainEditCover: 'Change the cover',
    settingsMainEditPhoto: 'Edit photo',
    settingMainAddPhoto: 'Add a photo',
    settingMainPhotoAlt: 'No photo',
    settingMainPlaceholderPhone: 'Enter your phone number',
    settingMainPlaceholderCountry: 'Select a country',
    settingMainPlaceholderCity: 'Choose a city',
    settingMainCountry: 'A country:',
    settingMainCity: 'City:',
    settingMainUnknow: 'Unknown',
    settingMainAbout: 'About me:',
    settingMainSave: 'Save',
    settingMainCancel: 'Cancel',

    settingDeleteTitle: 'After deleting a profile, all related information will be deleted: friends, posts, comments, likes.',
    settingDeleteLabel: 'Yes, delete my page and all related information',
    settingDeleteButton: 'Delete profile',
    settingDeleteCancel: `Don't delete the profile, I want to go back`,

    settingModalEmailChange: 'The email address has been successfully changed to',
    settingModalPasswordChange: 'Password changed successfully'
  },
}
