<template>
  <icon-box width="17" height="17" viewBox="0 0 17 17" fill="none" stroke="currentColor">
    <circle cx="7" cy="7" r="6.5" />
    <path d="M11.2998 11.6L15.4998 16" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
