<template>
  <icon-box width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_8602_302)">
      <path
        d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM10.711 10.711C10.3195 11.1025 9.669 11.083 9.2775 10.6915L7.294 8.708C7.20125 8.61625 7.128 8.505 7.07725 8.38175C7.02625 8.25975 7 8.13075 7 8V4C7 3.44725 7.44725 3 8 3C8.55275 3 9 3.44725 9 4V7.586L10.7148 9.30075C11.1065 9.6925 11.1015 10.3203 10.711 10.711Z"
        fill="#BDCDD6"
      />
    </g>
    <defs>
      <clipPath id="clip0_8602_302">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </icon-box>
</template>

<script>
import IconBox from "./IconBox.vue";

export default {
  components: { IconBox },
};
</script>
