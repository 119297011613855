<!-- eslint-disable max-len -->
<template>
  <icon-box width="21" height="21" viewBox="0 0 18 18" fill="none">
    <path
      d="M1.39191 0.279297C1.16249 0.279297 0.937616 0.370011 0.762561 0.544977C0.412482 0.895087 0.412477 1.45341 0.762561 1.80347L7.7413 8.78088L0.762561 15.7583C0.412477 16.1084 0.412482 16.6667 0.762561 17.0168C1.11267 17.3668 1.67117 17.3668 2.02125 17.0168L8.99999 10.0393L15.9787 17.0168C16.3288 17.3668 16.8873 17.3668 17.2374 17.0168C17.5875 16.6667 17.5875 16.1084 17.2374 15.7583L10.2587 8.78088L17.2374 1.80347C17.5875 1.45341 17.5875 0.895042 17.2374 0.544977C16.8873 0.195 16.3288 0.194955 15.9787 0.544977L8.99999 7.52241L2.02125 0.544977C1.84621 0.370011 1.62132 0.279342 1.39191 0.279297Z"
      fill="#BDCDD6" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
