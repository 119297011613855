<template>
  <icon-box width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M0 6.25H14V7.75H0V6.25Z" fill="#BCBCC7" />
    <path d="M7.75 6.55673e-08L7.75 14H6.25L6.25 0L7.75 6.55673e-08Z" fill="#BCBCC7" />
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
