<!-- src\components\MainLayout\LanguageSwitcher.vue -->

<template>
  <div class="language-switcher" >
    <LanguageIcon class="language-icon" />
    <div class="theme__change">
      <span>{{ translationsLang.languageTitle }}</span>
      <span class="theme__current" @click="toggleLanguage">
        {{ currentLanguageLabel }}
        <arrow-bottom />
      </span>
      <div class="theme__list" v-if="showLanguages" v-click-outside="closeLanguageList">
        <div
          class="theme__item"
          v-for="(language, index) in languages"
          :key="index"
          @click="onChangeLanguage(language)"
          :class="{ active: language.desc === storeLanguage.desc }"
        >
          {{ language.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import useTranslations from "@/composables/useTranslations";
import ArrowBottom from "@/Icons/ArrowBottom.vue";
import LanguageIcon from "@/Icons/LanguageIcon.vue";

export default {
  name: "LanguageSwitcher",
  components: {
    ArrowBottom,
    LanguageIcon,
  },
  setup() {
    const store = useStore();
    const { translationsLang } = useTranslations();
    const showLanguages = ref(false);

    const languages = [
      { id: 1, name: "Русский", desc: "RU" },
      { id: 2, name: "English", desc: "EN" },
    ];

    const storeLanguage = computed(() => store.state.auth.languages.language);

    const currentLanguageLabel = computed(() => {
      return storeLanguage.value.name;
    });

    const toggleLanguage = () => {
      showLanguages.value = !showLanguages.value;
    };

    const closeLanguageList = () => {
      showLanguages.value = false;
    };

    const onChangeLanguage = (language) => {
      store.commit("auth/languages/setLanguage", language);
      closeLanguageList();
    };

    // Следим за изменением языка в store и обновляем перевод
    watch(
      () => store.state.auth.languages.language,
      (newLanguage) => {
        store.commit("auth/languages/setLanguage", newLanguage);
      }
    );

    return {
      translationsLang,
      showLanguages,
      languages,
      currentLanguageLabel,
      toggleLanguage,
      closeLanguageList,
      onChangeLanguage,
      storeLanguage,
    };
  },
};
</script>
  
<style scoped lang="stylus">

.language-switcher
  display: flex
  align-items: center
  gap: 4px
  padding: 8px
  border-radius: 5px
  transition background-color 0.2s ease-in-out

.language-icon
  font-size: 18px
  margin-right: 6px

.theme__change
  display: flex
  align-items: center
  gap: 4px

.theme__current
  display: flex
  align-items: center
  padding: 4px 8px
  border-radius: 5px
  cursor: pointer

.theme__list
  position absolute
  top 100%
  left 0
  margin-top 4px
  border-radius 5px
  box-shadow 0px 4px 12px rgba(0, 0, 0, 0.1)
  z-index 10
  padding 5px
  min-width 130px

.theme__item
  padding 8px
  cursor pointer
  transition background-color 0.3s

  &:hover
    background-color #f0f0f0

  &.active
    font-weight bold
    background-color #d9d9d9

// Темная тема
[data-theme="dark"]
  .theme__current,
  .theme__item,
  .theme__change,
  .language-icon,
  .theme__current svg
    color #fff !important // Белый текст в темной теме
    background-color transparent // Убедиться, что фон прозрачен
    &:hover
      background-color #444 !important // Подсветка на hover
  .theme__current {
    background: #333;
}

// Светлая тема
[data-theme="light"]
  .theme__current,
  .theme__item,
  .theme__change,
  .language-icon
    color #333 !important // Темный текст в светлой теме
    background-color transparent
    &:hover
      background-color #e0e0e0 !important
 


</style>