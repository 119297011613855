<!-- eslint-disable max-len -->
<template>
  <icon-box width="25" height="25" viewBox="0 0 32 32" fill="none">
    <path
      d="M3.488 13.184l6.272 6.112-1.472 8.608 7.712-4.064 7.712 4.064-1.472-8.608 6.272-6.112-8.64-1.248-3.872-7.808-3.872 7.808z"
      fill="#ffd700"></path>
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
