// src\plugins\websocket.js

export default {
  install(app, options) {
    let socket;
    const messageHandlers = []; // Список функций-обработчиков для сообщений
    const store = options.store; // Получаем доступ к Vuex-хранилищу
 
    const socketApi = {
      connect() {

        const serverUrl = 'wss://himetrik.com/api/v1/streaming/ws';      //-прод
        // const serverUrl = 'ws://' + options.server + '/api/v1/streaming/ws' //-локал

        console.log(serverUrl);
        socket = new WebSocket(serverUrl);
        console.log('function connect');

        socket.onopen = () => {
          console.log('Websocket connected...');
        };
 
        socket.onmessage = (event) => {
          console.log("[WebSocket] Получено сообщение:", event.data);

          let serverMessage = null;
          try {
            serverMessage = JSON.parse(event.data);
            console.log("[WebSocket] Парсинг сообщения успешен:", serverMessage);

            // Проверка типа сообщения
            if (serverMessage && serverMessage.type === "MESSAGE") {
              console.log("socket.onmessage. Это сообщение с корректными данными:", serverMessage);

              // const targetDialogId = 
              // serverMessage.conversationPartner1 === store.getters['profile/info/getInfo'].id
              //   ? serverMessage.conversationPartner2
              //   : serverMessage.conversationPartner1;

                // console.log("[WebSocket] Сообщение для диалога:", targetDialogId);
                console.log("[WebSocket] Активный диалог ID в хранилище:", store.state.profile.dialogs.activeDialogId);

              // Убедимся, что сообщение принадлежит активному диалогу
              if (serverMessage.dialogId === store.state.profile.dialogs.activeDialogId) {
                console.log("[WebSocket] Сообщение добавлено в активный диалог:", serverMessage);

                store.commit('profile/dialogs/setNewMessage', serverMessage);
                store.commit('profile/dialogs/resetUnreadCount', serverMessage.dialogId);
              } else {
                console.warn("[WebSocket] Сообщение не относится к активному диалогу");

                store.commit('profile/dialogs/increaseUnreadCount', serverMessage.dialogId);
              }

            } else if (serverMessage.type === "NOTIFICATION") {
              console.log("socket.onmessage. Это уведомление:", serverMessage);
            } else {
              console.warn("socket.onmessage. Неизвестный тип сообщения или отсутствие данных:", serverMessage);
            }

          } catch (e) {
            console.log("socket.onmessage. Ошибка при разборе сообщения:", e);
          }

          // Вызываем все обработчики сообщений
          messageHandlers.forEach(handler => {
            console.log("socket.onmessage. Вызов обработчика:", handler);
            if (typeof handler === 'function') {
              handler(serverMessage);
            } else {
              console.error('socket.onmessage. Handler is not a function:', handler);
            }
          });
        };

        socket.onerror = (e) => {
          console.log('connect error!!', { e });
        };

        socket.onclose = (e) => {
          console.log(`[close] Соединение закрыто: код = ${e.code}, причина = ${e.reason}`, { e });
        };
      },

      sendMessage(payload) {
        const message = JSON.stringify(payload);
        console.log('Отправка сообщения:', message);
        socket.send(message);
      },

      subscribe(callback) {
        if (typeof callback === 'function') {
          messageHandlers.push(callback);
        } else {
          console.error('Handler is not a function:', callback);
        }
      }
    };

    app.config.globalProperties.$socket = socketApi;
  },
};




