// src\settings\router.js

import router from '@/router';
import store from '@/store';

router.beforeEach(async (to, from, next) => {
  // Устанавливаем заголовок страницы, если он указан в meta
  if (to.meta.title) document.title = to.meta.title;

  // Выполняем асинхронную проверку авторизации
  const isAuthenticated = await store.getters['auth/api/isAuthenticated'];

  // Если маршрут требует авторизации и пользователь не авторизован, перенаправляем на логин
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ 
        name: 'Login',
        query: { redirect: to.name },
      });
    } else {
      next();
    }
    return;
  }

  // Если маршрут доступен только неавторизованным пользователям, и пользователь уже авторизован
  if (to.matched.some((record) => record.meta.notWithoutAuth) && isAuthenticated) {
    next({ name: 'News' });
    return;
  }

  // В остальных случаях продолжаем навигацию
  next();
});

