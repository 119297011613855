// C:\Users\Timur\vsCodeProjects\frontend-vue3\src\settings\axios.js

import axios from 'axios';
import store from '@/store';

// Настройки axios для работы с куками
axios.defaults.withCredentials = true;  // Включаем отправку cookies
axios.defaults.baseURL = '/api/v1/';    // Настраиваем базовый URL

// Обработка ошибок
axios.interceptors.response.use(null, async (error) => {
  const originalRequest = error.config;

  // Обрабатываем ошибки 401 (неавторизован)
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      // Извлекаем refreshToken из куков
      const refreshTokenCookie = document.cookie.split('; ').find(row => row.startsWith('refreshToken'));
      console.log('Кука refreshToken найдена:', refreshTokenCookie);

      // Проверяем, существует ли refreshToken
      if (!refreshTokenCookie) {
        throw new Error('Refresh token not found in cookies');
      }

      const refreshToken = refreshTokenCookie.split('=')[1];
      console.log('Using refresh token:', refreshToken); // Логирование перед отправкой

      // Отправляем запрос на обновление токена
      const response = await axios.post('/auth/refresh', { refreshToken }, { withCredentials: true });
      
      // Если обновление прошло успешно, повторяем оригинальный запрос
      if (response.status === 200) {
        return axios(originalRequest);
      } else {
        throw new Error('Token refresh failed');
      }

    } catch (err) {
      console.error('Token refresh failed', err);
      // Выполняем logout, если обновление токена не удалось
      await store.dispatch('auth/logout');
      window.location.href = '/login';  // Или используйте роутинг приложения

      return Promise.reject(err);
    }
  }

  return Promise.reject(error);
});

export default axios;
 