<!-- eslint-disable max-len -->
<!-- eslint-disable max-attributes-per-line -->
<template>
  <icon-box width="16" height="16" viewBox="0 0 512 512" fill="#000000">
    <g>
      <path d="M468.277,116.662c-27.602-40.846-66.66-73.285-112.616-92.728c-30.638-12.955-64.35-20.122-99.654-20.122
        c-35.303,0-68.914,7.166-99.421,20.135c-30.507,12.97-57.911,31.72-80.944,54.876C29.582,125.11,0.966,189.104,0.014,259.601v0.013
        C0.007,260.484,0,261.354,0,262.21c0,18.834,2.796,34.96,7.865,48.553c5.056,13.585,12.442,24.608,21.286,32.857
        c5.892,5.508,12.407,9.77,19.176,12.812c9.037,4.056,18.491,5.946,27.658,5.946c8.187,0,16.147-1.493,23.485-4.384
        c7.331-2.898,14.065-7.2,19.587-12.921c14.641-15.181,35.708-24.334,59.042-24.341c17.401,0.007,33.44,5.262,46.826,14.298
        c13.373,9.03,24.019,21.854,30.363,36.865c4.234,10.016,6.577,20.998,6.577,32.603c0.007,4.679-0.753,9.551-1.966,14.894
        c-1.192,5.33-2.843,11.071-4.281,17.415c-1.35,5.988-2.014,11.722-2.014,17.196c-0.028,10.125,2.37,19.388,6.694,27.061
        c4.302,7.694,10.406,13.654,17.072,17.867c4.446,2.822,9.153,4.905,13.914,6.385c6.351,1.96,12.798,2.864,19.053,2.871
        c5.857-0.007,11.564-0.788,16.976-2.46c52.951-16.387,99.058-48.594,132.012-91.364c32.946-42.75,52.69-96.215,52.677-154.558
        C512.013,206.841,495.872,157.507,468.277,116.662z M435.803,396.25c-29.157,37.872-70.167,66.564-117.261,81.129
        c-2.165,0.671-5.076,1.137-8.207,1.124c-3.775,0.013-7.852-0.644-11.483-1.946c-3.652-1.294-6.816-3.192-9.235-5.549
        c-1.616-1.576-2.932-3.357-3.98-5.508c-1.384-2.878-2.343-6.482-2.357-11.496c0-3.042,0.37-6.584,1.295-10.674
        c1.164-5.214,2.822-10.975,4.281-17.408c1.446-6.427,2.686-13.565,2.686-21.424c0.006-23.443-7.153-45.353-19.382-63.439
        c-12.229-18.1-29.52-32.467-49.902-41.086c-13.579-5.748-28.541-8.926-44.161-8.926c-31.124,0-60.014,12.277-80.403,33.418
        c-2.274,2.357-5.378,4.44-9.118,5.92c-3.727,1.466-8.057,2.316-12.592,2.316c-5.714,0-11.715-1.329-17.402-4.268
        c-5.686-2.953-11.133-7.475-15.867-14.312c-3.151-4.563-5.981-10.167-8.2-17.073c-2.967-9.194-4.837-20.703-4.837-34.837
        c0-0.733,0.007-1.472,0.014-2.213v0.007c0.637-47.012,15.072-90.563,39.44-126.73c24.382-36.159,58.699-64.858,99.065-82.012
        c26.917-11.442,56.521-17.771,87.809-17.771c46.922,0.007,90.385,14.236,126.524,38.639c36.132,24.403,64.844,58.994,82.013,99.585
        c11.448,27.068,17.778,56.802,17.778,88.09C482.308,311.428,464.961,358.364,435.803,396.25z" />
      <path d="M392.273,296.061c-6.221-2.631-13.086-4.09-20.225-4.09c-10.708-0.007-20.772,3.282-29.048,8.886
        c-8.282,5.59-14.846,13.496-18.799,22.834c-2.637,6.22-4.09,13.085-4.09,20.217c-0.007,10.715,3.282,20.779,8.879,29.048
        c5.597,8.29,13.503,14.852,22.841,18.806c6.22,2.638,13.085,4.09,20.217,4.09c10.716,0,20.78-3.281,29.055-8.879
        c8.276-5.604,14.846-13.503,18.799-22.841c2.638-6.221,4.09-13.085,4.09-20.224c0.007-10.708-3.281-20.772-8.878-29.048
        C409.516,306.584,401.61,300.007,392.273,296.061z M393.594,358.418c-2.802,4.152-6.796,7.467-11.44,9.427
        c-3.097,1.302-6.482,2.028-10.106,2.035c-5.418-0.007-10.345-1.624-14.503-4.433c-4.152-2.795-7.468-6.789-9.427-11.434
        c-1.308-3.096-2.034-6.482-2.034-10.106c0.006-5.419,1.623-10.345,4.425-14.51c2.802-4.152,6.796-7.454,11.441-9.42
        c3.097-1.308,6.481-2.034,10.098-2.034c5.426,0.006,10.352,1.63,14.518,4.426c4.145,2.809,7.461,6.796,9.42,11.441
        c1.309,3.097,2.035,6.481,2.035,10.098C398.014,349.334,396.396,354.26,393.594,358.418z" />
      <path d="M126.462,175.094c-20.998,0-38.022,17.024-38.022,38.03c0,20.991,17.024,38.023,38.022,38.023
        c21.005,0,38.03-17.032,38.03-38.023C164.492,192.118,147.467,175.094,126.462,175.094z" />
      <path d="M193.245,100.274c-18.265,0-33.076,14.812-33.076,33.077c0,18.271,14.812,33.083,33.076,33.083
        c18.271,0,33.084-14.812,33.084-33.083C226.329,115.086,211.516,100.274,193.245,100.274z" />
      <path d="M288.782,90.382c-16.387,0-29.679,13.29-29.679,29.678c0,16.388,13.292,29.679,29.679,29.679
        s29.678-13.291,29.678-29.679C318.46,103.672,305.169,90.382,288.782,90.382z" />
      <path d="M361.745,190.392c14.599,0,26.431-11.838,26.431-26.431c0-14.599-11.832-26.431-26.431-26.431
        c-14.6,0-26.431,11.832-26.431,26.431C335.314,178.553,347.145,190.392,361.745,190.392z" />
      <path d="M376.933,233.834c0,11.42,9.262,20.669,20.676,20.669c11.42,0,20.676-9.249,20.676-20.669
        c0-11.42-9.256-20.67-20.676-20.67C386.195,213.164,376.933,222.414,376.933,233.834z" />
    </g>
  </icon-box>
</template>

<script>
import IconBox from './IconBox.vue';

export default {
  components: { IconBox },
};
</script>
